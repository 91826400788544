<template>
<div class="section-wrapper">
  <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
    <div class="wrapper-box">
      <div class="title">
        <p class="cn">
          <span>发展</span>
          <span>历程</span>
        </p>
        <p class="en">DEVELOPMENT HISTORY</p>
      </div>
      <div class="history">
        <ul>
          <li v-for="(item,index) in content" :key="index">
            <div class="year">
              {{item.year}}
            </div>
            <div class="box">
              <div class="time">{{item.year}}年</div>
              <div class="content">
                <p v-for="(subitem,subindex) in item.events" :key="subindex">{{subitem.text}}</p>
              </div>
              <div class="zoom-img" v-show="item.imgUrl">
                <img :src="urlTrans(item.imgUrl)" alt="">
              </div>
            </div>
          </li>
        </ul>

      </div>
      
    </div>
  </div>
</div>
 
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue"
export default {
  name: "developmentHistory",
  components: {
    breadcrumbs
  },
  props: {
  },
  data() {
    return {
        content:[
          // {
          //   year:2021,
          //   events:[
          //     {
          //       text:'公司被认定为“上海市‘专精特新’中小企业”',
          //     },
          //     {
          //       text:'“上海智创交通安全工程技术研究院”成立公司历程',
          //     },
          //   ],
          //   imgUrl:process.env.BASE_URL + "img/cover.jpg"
          // },
          // {
          //   year:2021,
          //   events:[
          //     {
          //       text:'认证机构扩大范围，认证领域：城市轨道交通装备产品',
          //     },
          //     {
          //       text:'公司被认定为“高新技术企业”',
          //     },
          //   ],
          //   imgUrl:""
          // },
        ],
        Tpage: {
            currentPage: 1,
            currentSize: 100
          },
          lang:"中文",
        
    };
  },
  created(){
    this.getData();
  },
  methods: {
    linkTo: function (value) {
      location.href = value;
    },
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + '/flist', {
          askData:'history',
          page: this.Tpage,
          lang:this.lang,
        })
        .then(
          response => {
            this.content = response.data.res_record.reverse();
            this.spin = false;
          }
        );
    },
    urlTrans(url) {
      // return url;
      if(url){
        return this.$fileUrlFacWithoutRight(url);
      }else{
        return "";
      }
      
    },
  },
  computed:{
      breadcrumbsContents:function(){
          return [{text:'首页',url:'/'},{text:'发展历程',url:'/developmentHistory'},];
      },
  },
  mounted() {},
};
</script>
<style scoped>

.section-wrapper{
  width:100%;
}

.sub-section-wrapper{
  width: 1200px;
  margin:0 auto;
  overflow: hidden;
  position: relative;
  padding-bottom: 80px;
}

.title{
  text-align: center;
  margin-bottom: 50px;
}

.title .cn{
  font-size: 32px;
  font-weight: 800;
  color:#111;
  line-height: 1.5;
}

.title .en{
  font-size: 16px;
  color:#888;
  line-height: 1.5;
}

.title .cn span:last-child{
  color:#00508e;
}

.history{
  padding-bottom: 16px;
  position: relative;
  min-height:500px;
}

.history:before {
    content: "";
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
    bottom: 30px;
    width: 2px;
    background: rgba(102,102,102,.2);
}

:before {
    -webkit-transition: All .5s ease;
    transition: All .5s ease;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
li {
    list-style: outside none;
    list-style-position: outside;
    list-style-image: initial;
    list-style-type: none;
    display: list-item;
    text-align: -webkit-match-parent;
    position: relative;
}
.history li{
  margin-bottom:25px;
  margin-left:100px;
}

.history li .year {
    position: absolute;
    left:-47px;
    top: 20px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #d1d1d1;
    font-size: 0;
    text-align: center;
    color: rgba(0,0,0,0);
    box-sizing: border-box;
}

.history li .year:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 24px;
    height: 24px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: rgba(0,0,0,.1) solid 1px;
    border-radius: 50%;
    box-sizing: border-box;
}

.history li .box {
  margin-left:50px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border: #e5e5e5 solid 1px;
    padding: 10px 30px;
    font-size: 13px;
    color:#666;
    overflow: hidden;
}

.history li .box .time {
  font-size: 24px;
  color:#00508e;
  font-weight: 600;
    width: 100px;
    margin: 0 30px;
    position: relative;
}

.history li .box .content {
    line-height: 2.4;
    font-size: 16px;
}

.history li:hover .year{
  width: 120px;
    height: 120px;
    background: #00508e;
    left:-100px;
    font-size: 32px;
    color:#fff;
    font-weight: 600;
    line-height: 116px;
}

.history li:hover .year:before{
  width: 130px;
    height: 130px;
    background: #00508e;
    opacity: 20%;
}

.history li:hover .box{
  min-height:180px;
  background-color: #00508e;
  
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-box;
  display:block;
  color:#fff;
  padding: 30px 50px;
  border:none;
}

.history li:hover .time{
  width:100%;
  color:#fff;
  font-size: 32px;
  margin:0 0 20px 0;
}

.history li:hover .time:after{
  content: '';
  position: absolute;
  top: 50px;
  left:0;
  background: #fff;
  width: 50px;
  height: 3px;
}

.zoom-img{
  display: none;
  height:100%;
  position: absolute;
  right:0px;
  top:0;
  overflow:hidden;
  }

  .zoom-img img{
    height:100%;
    border:0;
    transition: All .5s ease;
    object-fit: cover;
    position: relative;
  }

  .zoom-img img:hover{
    transform:scale(1.1);
  }

.history li:hover .zoom-img{
  display: block;
  float: right;
}


</style>